import React from 'react';
import { MainLayout } from '../../layouts'
import { Footer, HeroSection, LineDotContainer, Paragraph, Title } from "../../components";


import styles from './styles.module.css'

export const InternationalTermsOfBusiness = () => {
    return (
        <MainLayout>
            <HeroSection className={styles.customHero}>
                <Title colour="white" weight="bold" size="large">
                    Terms of Business
                </Title>
            </HeroSection>
            <div className={styles.innerContent}>
                <LineDotContainer>
                    <Title>Terms of Business</Title>
                    <p>
                        The following terms and conditions, as modified by any variation from time to time as agreed between Calibrate Consulting and Client in writing, will apply, either generally or in respect of a specific matter, as appropriate, to Calibrate Consulting’s provision of services to Client. In the case of any inconsistency between these Terms of Business and any terms and conditions agreed between Calibrate Consulting and Client in writing at any time, the latter shall prevail.
                    </p>
                    <div className="tb_text_wrap">
                        <p><strong>1 – Scope of Engagement</strong><br />Calibrate Consulting hereby agrees to perform the
                            services (“Services”) described in the attached proposal to client&nbsp; (“Proposal”). The
                            scope of Calibrate Consulting’s engagement in relation to each project will be
                            agreed&nbsp;between Calibrate Consulting and Client from time to time. The “Agreement”
                            consists of these Terms of Business together with the Proposal. No other terms and
                            conditions or documents shall form part of the Agreement unless expressly agreed in writing
                            by authorised representatives of Calibrate Consulting and Client.</p>
                        <p><strong>2 – Acceptance and Authority to Act</strong><br />Following receipt of a Proposal from
                            Calibrate Consulting by the Client, any further instruction by the Client relating to the
                            Services shall be deemed to be acceptance of, and agreement to, Calibrate Consulting’s terms
                            and conditions, including any fees payable.</p>
                        <p><strong>3 – Fees and Billing</strong><br /><strong>3.1 –</strong> In accordance with the
                            Proposal, Client agrees to pay Calibrate Consulting for the Services rendered and to
                            reimburse Calibrate Consulting for expenses incurred in connection with providing the
                            Services.<br /><strong>3.2 –</strong> Calibrate Consulting will submit bills to Client in
                            accordance with either agreed arrangements or otherwise at such intervals as Calibrate
                            Consulting considers appropriate. Each bill will include a description of the work
                            undertaken by Calibrate Consulting. Accounts should be settled within 30
                            days.<br />
                            <strong>3.3 –</strong>
                            Calibrate Consulting reserves the right to charge interest on any overdue sum from the due date until payment of the overdue sum, whether before or after judgement. Interest under this clause shall accrue each day at three per cent a year above the base rate from time to time of Barclays Bank plc in London or an equivalent or alternative rate in other jurisdictions, if appropriate as may be notified to Client (or, where a late payment interest rate is provided by the relevant legislation, at the rate set out in such legislation).
                            <br /><strong>3.4 –</strong> All sums payable by Client must be
                            paid free and clear of any deductions or withholdings (together “Withholdings”),
                            except as required by law. If any Withholdings are so required, unless otherwise
                            agreed between Calibrate Consulting and Client in writing, Client will pay
                            Calibrate Consulting such sum as will leave Calibrate Consulting with the same
                            amount as it would have received in the absence of a requirement to make a
                            Withholding.<br /><strong>3.5 –</strong> Where Calibrate Consulting is required to
                            do so, value added tax (or its equivalent) or any other tax imposed on the
                            provision of services will be charged to Client in addition to the amounts
                            charged in accordance with the Proposal.</p>
                        <p><strong>4 – Cooperation</strong><br /><strong>4.1 –</strong> Both Calibrate Consulting and
                            Client acknowledge and agree that successful undertaking and completion of the Services will
                            require full and mutual good faith cooperation of both parties.<br /><strong>4.2
                                –</strong> The tasks to be performed by Client personnel, including staffing
                            requirements, and the obligations of Client regarding such personnel, are in accordance
                            with the Proposal. In addition to performing those tasks identified in the Proposal,
                            Client agrees to provide such services, equipment, software and support as are stated to
                            be Client’s responsibility in the Proposal.<br /><strong>4.3 – </strong>Client further
                            undertakes that information provided to Calibrate Consulting shall be complete,
                            accurate and prompt, that its reviews of Calibrate Consulting work shall be timely
                            and shall be performed by personnel fully familiar with Client’s business and
                            requirements and the terms of the Proposal.</p>
                        <p><strong>5 – Confidentiality</strong><br /><strong>5.1 –</strong> Each party as well as their
                            affiliates (defined as legal entities directly or indirectly controlled by, controlling or
                            under common control with one of the parties) shall keep confidential and shall not use
                            (except in connection with this Agreement) any confidential information disclosed to it by
                            the other party or of which it becomes aware relating to the other party other
                            than <strong>(i)</strong> where required to do so by any applicable law, rules or court
                            order having taken, where practicable and at the other party’s expense, any action which the
                            other party reasonably requests to contest the disclosure after informing such party of the
                            requirements, where permitted to do so; <strong>(ii)</strong> to anyone (including other
                            advisers, professional or otherwise) where such party considers that it is appropriate for
                            that person to know such confidential information, taking into account the other party’s
                            interests, in order to assist in the provision of the Services;
                            and <strong>(iii)</strong> by Calibrate Consulting to selected third parties such as word
                            processing, translation, waste disposal agencies, IT service providers and other outsourced
                            business services suppliers who assist Calibrate Consulting with legal, finance,
                            administrative and other roles, and who will or may have access to confidential information
                            as part of their function. Calibrate Consulting will ensure by means of appropriate
                            confidentiality agreements and, where possible, technological restrictions, that
                            confidential information will be protected.<br /><strong>5.2 –</strong> The parties agree that
                            “confidential information” does not include the
                            following: <strong>(i)</strong> information that is in the public domain or in the
                            possession of the receiving party at the time of disclosure to that party by the other
                            party, <strong>(ii)</strong> information independently developed by a party without use
                            of confidential information, <strong>(iii)</strong> information that subsequently comes
                            into the public domain through no fault of the receiving party, and (iv) information
                            that is also disclosed to the receiving party by a third party who is under no
                            obligation to the disclosing party to keep that information confidential.<br /><strong>5.3
                                –</strong> Notwithstanding this section 4, Calibrate Consulting may serve other
                            clients in the same industry as Client as long as it does so without compromising
                            its responsibility to maintain the confidentiality of Client’s confidential
                            information including parties with whom Client competes or has a commercial
                            relationship.<br /><strong>5.4 –</strong> Calibrate Consulting owes the same duty of
                            confidentiality to all of its clients, therefore, Calibrate Consulting will not
                            disclose to you any information given to it in confidence in relation to any
                            matter even if it is material or relevant to matters concerning Client. Client
                            agrees that Calibrate Consulting does not owe a duty of disclosure to Client in
                            relation to such information.<br /><strong>5.5 –</strong> Client acknowledges and
                            if required to do so it will advise its own customers and/or users that Data
                            may be located in foreign jurisdictions, and that applicable laws in foreign
                            jurisdictions may permit foreign governments, courts, law enforcement or
                            regularity agencies to access Data in those jurisdictions.</p>
                        <p><strong>6 – Use of Information and Work Product</strong><br /><strong>6.1</strong>
                            <strong>–</strong> Client understands and agrees that in performing the project outlined in
                            the Proposal, Calibrate Consulting will be using publicly available information and
                            information in reports and other material provided by others, including, without limitation,
                            information provided by Client, and that Calibrate Consulting does not assume responsibility
                            for and may rely, without independent verification, on the accuracy and completeness of any
                            such information. Calibrate Consulting’s findings and recommendations will reflect its best
                            judgment based on the information available to it.<br /><strong>6.2 –</strong> Client
                            understands and agrees that all reports, analyses or other material provided by
                            Calibrate Consulting are solely for Client’s information and use in connection with the
                            assignment and may not be delivered to any third party, or published anywhere in any
                            form, without the prior written consent of Calibrate Consulting, except as required by
                            law. Calibrate Consulting shall retain all rights to concepts, approaches,
                            methodologies, models, tools, generic industry information, knowledge and experience
                            possessed by Calibrate Consulting prior to, or acquired by it during, the performance of
                            the assignment.</p>
                        <p><strong>7 – Independent Contractor</strong><br />Calibrate Consulting is an independent
                            contractor and not an agent or representative of Client. No employee of Calibrate Consulting
                            shall be deemed an employee of Client. Except as otherwise expressly agreed, Client will not
                            have control over Calibrate Consulting or its employees. Calibrate Consulting agrees that it
                            shall be Calibrate Consulting’s responsibility to pay income taxes, national insurance and
                            social security amounts, required by law to be paid in respect of the employees performing
                            services hereunder.</p>
                        <p><strong>8 – Non-solicitation</strong><br />Unless otherwise agreed to by Calibrate Consulting
                            in writing, Client hereby undertakes to Calibrate Consulting that it shall not hire or
                            solicit the employment of any member of Calibrate Consulting’s personnel directly or
                            indirectly associated with Calibrate Consulting’s provision of Services under this
                            Agreement, whether permanently, temporarily, on a part-time basis, as a contractor or
                            otherwise, or encourage such person to apply for any such position or post, during the
                            duration of this Agreement and for a period of twelve (12) months thereafter. Calibrate
                            Consulting acknowledges that the preceding sentence does not apply to persons responding
                            independently to the general media advertising made by, or on behalf of, Client.</p>
                        <p><strong>9 – Third-Party Hardware and Software</strong><br /><strong>9.1 –</strong> The parties
                            understand that any work product delivered under this Agreement may include certain
                            third-party hardware and/or software products. It is acknowledged by Client that Client
                            shall be solely responsible for obtaining licences to such third-party software, if such
                            software is not already in Client’s possession, including the right to incorporate such
                            software into its systems.<br /><strong>9.2 –</strong> Calibrate Consulting makes no
                            warranties or representations hereunder, and there are no conditions applicable, express
                            or implied, as to the quality, capabilities, operations, performance or suitability of
                            any third party hardware or software including the ability to integrate with any
                            software of or developed for Client, and the quality, capabilities, operations,
                            performance and suitability of such third-party hardware or software lie solely with
                            Client and the vendor or supplier of that hardware or software.</p>
                        <p><strong>10 – Limitation of Liability</strong><br /><strong>10.1 –</strong> Calibrate Consulting
                            shall have no liability with respect to its obligations under this Agreement or otherwise
                            for loss of revenue, loss of anticipated savings or any consequential loss or damages even
                            if it has been advised of the possibility of such losses or damages and whether such losses
                            or damages arise under breach of contract, breach of warranty, condition or other term,
                            negligence, strict liability, breach of statutory duty, misrepresentation, tort or
                            otherwise.<br /><strong>10.2 –</strong> In any event, and even if the exclusion in section
                            10.1 is found for any reason to be unenforceable, the total aggregate liability of
                            Calibrate Consulting to Client for any reason under or in connection with this Agreement
                            shall be limited to an amount equal to 100% of the amount payable for the services that
                            gave rise to the liability. This limitation applies to all causes of action, including
                            without limitation to breach of contract, breach of warranty, condition or other term,
                            negligence, strict liability, breach of statutory duty, misrepresentation, tort or
                            otherwise.<br /><strong>10.3 –</strong> Nothing in this Agreement shall exclude or in any
                            way limit Calibrate Consulting’s liability to Client for fraud, death or personal
                            injury caused by its negligence (including negligence as defined in the Unfair
                            Contract Terms Act 1977), breach of terms regarding title implied by statute or any
                            liability to the extent the same may not be excluded or limited as a matter of law.
                        </p>
                        <p><strong>11 – Indemnification</strong><br /><strong>11.1 –</strong> Subject to section 11.2,
                            Client hereby agrees to indemnify and hold Calibrate Consulting harmless from and against
                            all third party claims, liability, losses, damages and expenses as they are incurred,
                            including without limitation legal fees and disbursements and the cost of Calibrate
                            Consulting’s personnel time at normal professional rates, relating to or arising out of the
                            Services.<br /><strong>11.2 –</strong> Client’s liability under this indemnity shall be
                            reduced to the extent that any such claims, liability, losses, damages or expenses
                            result from the negligence, recklessness or wilful default of Calibrate Consulting.</p>
                        <p><strong>12 – Dispute Resolution</strong><br />Any dispute arising out of or in connection with
                            this Agreement, including any question regarding its existence, validity or termination,
                            shall be referred to and finally resolved by arbitration under the LCIA Rules, which are
                            deemed to be incorporated by reference into this section 12. The number of arbitrators shall
                            be one. The seat, or legal place, of arbitration shall be London. The language to be used in
                            the arbitration proceedings shall be English.</p>
                        <p><strong>13 – Termination</strong><br /><strong>13.1 –</strong> If either party hereto ceases to
                            trade, is unable to pay its debts as they fall due, becomes or is declared insolvent or
                            bankrupt, is the subject of any proceedings relating to its liquidation, insolvency or for
                            the appointment of a receiver, administrative receiver, administrator or similar officer for
                            it, makes an assignment for the benefit of all or substantially all of its creditors, or
                            enters into an agreement for the composition, extension, or readjustment of all or
                            substantially all of its obligations, or has an order made for its dissolution or
                            liquidation (other than for the purpose of solvent amalgamation or reconstruction) then the
                            other party hereto may, by giving written notice thereof to such party, terminate this
                            Agreement as of a date specified in such notice of termination.<br /><strong>13.2
                                –</strong> Client may terminate this Agreement at any time by giving Calibrate
                            Consulting thirty days’ written notice to that effect. In such event, Calibrate
                            Consulting shall be entitled to payment for Services rendered and for expenses and
                            obligations incurred in connection with this Agreement prior to the effective date of
                            termination.</p>
                        <p><strong>14 – Laws and Regulations</strong><br />Calibrate Consulting agrees, in connection with
                            the Services being provided in accordance with the Proposal, to comply with all applicable
                            laws and regulations.</p>
                        <p><strong>15 – No Third-Party Beneficiaries</strong><br />Calibrate Consulting and the Client
                            agree that this Agreement is intended to be solely for the benefit of the parties hereto and
                            that no third parties may rely on any reports, analyses or other material provided by
                            Calibrate Consulting or shall obtain any direct or indirect benefits from the Agreement,
                            have any claim or be entitled to any right or remedy under this Agreement or otherwise in
                            any way be regarded as third-party beneficiaries under this Agreement. Accordingly, the
                            parties intend that the Contracts (Rights of Third Parties) Act 1999 shall have no
                            application to this Agreement.</p>
                        <p><strong>16 – Anti-Money Laundering and Other Sanctions</strong><br /><strong>16.1
                            –</strong> Calibrate Consulting is subject to laws and regulations on anti-money laundering.
                            Calibrate Consulting may ask Client to provide it with relevant information for the purposes
                            of performing customer due diligence checks (e.g. verification of identity and/or evidence
                            of source of funds), which Client agrees to supply to Calibrate Consulting promptly on
                            request. Client also consents to Calibrate Consulting conducting electronic verification of
                            identity.<br /><strong>16.2 –</strong> Calibrate Consulting may be required to report to the
                            relevant<br />authorities any suspicious activity, and obtain the prior consent of the
                            relevant authorities before continuing to provide Services to Client. Calibrate
                            Consulting may be prohibited from informing Client that it has made such report
                            (i.e. tip-off). Calibrate Consulting will not be liable to Client for any loss,
                            damage or delay Client may suffer as a result of Calibrate
                            Consulting <strong>(i)</strong> ceasing to provide Services in accordance with the
                            paragraph above; or <strong>(ii) </strong>fulfilling its statutory obligations (or
                            in acting as it may reasonably believe it is required to do so), so long as it has
                            acted in good faith.</p>
                        <p><strong>17 – Marketing and Other Publications</strong>
                            <br />
                            Client agrees that Calibrate Consulting may disclose in generic form in marketing or similar
                            materials that Client is a client of Calibrate Consulting and that it is providing Services
                            to Client.
                            <br />
                        </p>
                        <p><strong>18 – Assignment</strong><br />Client and Calibrate Consulting agree that neither party
                            may assign its rights under this Agreement without the prior written consent of the other
                            party.</p>
                        <p><strong>19 – Force Majeure</strong><br />Neither party shall be liable to the other for any
                            delay or failure to perform any of the Services or obligations set forth in this Agreement
                            due to a cause beyond its reasonable control. Performance times shall be extended for the
                            period required to make up the work lost because of such cause.</p>
                        <p><strong>20 – Miscellaneous</strong><br /><strong>20.1 –</strong> The Proposal and these Terms
                            of Business represent the entire<br />agreement and understanding between the parties with
                            respect to the subject hereof, and supersede all prior discussions and writings with
                            respect hereto. No representation, undertaking or promise shall be taken to have been
                            given or be implied from anything said or written in negotiations between the parties
                            prior to this Agreement except as expressly stated in this Agreement. Neither party
                            shall have any remedy in respect of any untrue statement made by the other upon which
                            that party relied in entering into this Agreement (unless such untrue statement was made
                            fraudulently) and that party’s only remedies shall be for breach of contract as provided
                            in this Agreement.<br /><strong>20.2 –</strong> This Agreement shall be governed by, and
                            construed in accordance with, the laws of England.<br /><strong>20.3 –</strong> No
                            modification, alteration or amendment of this Agreement shall be effective
                            unless contained in writing that is signed by both parties and this new
                            agreement (“Variation Agreement”) specifically refers to this Agreement. With
                            the exception of the amendments contained in the Variation Agreement, all other
                            terms and conditions will remain unchanged. Where Calibrate Consulting and the
                            Client have duly entered into a Variation Agreement and the Client breaches any
                            of terms and conditions of the Variation Agreement then Calibrate Consulting may
                            unilaterally revert to this Agreement. In the event that any term or provision
                            of this Agreement shall, for any reason, be held to be illegal, invalid or
                            unenforceable under any applicable law, such term or provision shall be deemed
                            severed from this Agreement, and the remaining terms and provisions will be
                            unaffected thereby.<br /><strong>20.4 –</strong> The failure of either party to
                            enforce or to exercise at any time or for<br />any period of time any term of
                            or any right pursuant to this Agreement does not constitute, and shall
                            not be construed as, a waiver of such term or right and shall in no way
                            affect that party’s right later to enforce or to exercise
                            it.<br /><strong>20.5 –</strong> The headings in these Terms of Business
                            are inserted for convenience only and shall not constitute a part of
                            the Terms of Business or affect in any way the meaning or
                            interpretation of the Agreement.<br /><strong>20.6
                                –</strong> Calibrate Consulting carries on business in a number
                            of jurisdictions through different entities. All references to
                            “Calibrate Consulting” in these Terms of Business shall refer to
                            Calibrate Consulting Inc., Product Control Limited or any other
                            subsidiary or holding company of the Calibrate Consulting group,
                            as appropriate.</p>
                    </div>
                </LineDotContainer>
            </div>
            <Footer />
        </MainLayout>
    )
}